import React from "react"
import PropTypes from "prop-types"

import logo from "../../images/logos/logo_rackmarkt_bigsize.png"
import { Link } from "gatsby"
import styled from "styled-components"
import { Container } from "../../utils/components"
import Navigation from "./Navigation";


const Root = styled.header`
  width: 100%;
  height: 74px;
  background-color: ${({ background }) => background || 'transparen'};
  z-index: 100;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 16px;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  height: 40px;
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
  }
`;

const Header = ({ siteTitle, menuItems }) => (
  <Root>
    <HeaderContainer>
      <Logo to="/">
        <img src={logo} alt={siteTitle} />
      </Logo>
      <Navigation items={menuItems} />
    </HeaderContainer>
  </Root>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Rackmarkt`,
}

export default Header
