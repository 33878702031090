import React, { useState } from "react"

import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { Container, } from "../../utils/components"
import media from "../../utils/mediaQueries"

const NavBar = styled.nav`
  display: none;
  justify-content: center;
  display: none;
  ${props => media(props).greaterThan("sm")`
    display: flex;
  `};
`;

const MenuItems = styled.ul`
  list-style: none;
  //grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(7,min-content);
`;

const MenuItem = styled.li`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
`;

const HeaderLink = styled(Link)`
  color: ${props => props.color || props.theme.header.textColor};
  //font-family: "${({ theme }) => theme.fontFamilies.text}";
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1rem;
  border-color: transparent;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  position: relative;
  padding: .5rem;
  cursor: pointer;
  ${props => media(props).greaterThan("md")`
    margin: 0 .5rem;
  `};
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0px;
    height: 2px;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.5s;
    opacity: 0;
    background: ${props => props.color || props.theme.header.textColor};
  }
  &:before {
    left: calc(50% - 0.5rem);
  }
  &:after {
    right: calc(50% - 0.5rem);
  }
  &:hover {
    -webkit-text-stroke: 1px ${props => props.color || props.theme.header.textColor};
    color: ${props => props.color || props.theme.header.textColor};
      &:before,
      &:after {
      width: 50%;
      opacity: 1;
    }
  }
  ${({ active }) => active ? css`
    -webkit-text-stroke: 1px ${props => props.color || props.theme.header.textColor};
    color: ${props => props.color || props.theme.header.textColor};
      &:before,
      &:after {
      width: 50%;
      opacity: 1;
    }
  ` : ''
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: ${prop => prop.open ? 'flex' : 'none'};
  width: 100%;
  top: 70px;
  left: 0px;
  right: 0px;
  z-index: 200;
  padding: 2rem;
  padding-top: 50px;
  transition-duration: 0.5s;
  background: ${props => props.bgColor || props.theme.header.subMenu.bgColor};
  border-top: 3px solid ${props => props.accentColor || props.theme.header.subMenu.accentColor};
  box-shadow: ${props => props.theme.boxShadow.md}; 
`;

const SubMenuContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`
const SubMenuLink = styled(Link)`
  color: ${props => props.textColor || props.theme.header.subMenu.textColor};
`;


const Navigation = ({ items }) => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const mouseEnter = (selectedMenu) => {
    console.log('mouse enter')
    setSelectedMenu(selectedMenu);
  }

  const mouseLeave = () => {
    console.log('mouse leave')
    //setSelectedMenu(null);
  }
  return (
    <NavBar>
      <MenuItems>
        {
          items.map(({ label, to, children }, menuIndex) => {
            return (
              <MenuItem
                key={label}
                onMouseEnter={() => mouseEnter(menuIndex)}
                onMouseLeave={mouseLeave}
              >
                <HeaderLink
                  active={selectedMenu === menuIndex}
                  color="#247393"
                  to={to}
                >
                  {label}
                </HeaderLink>
                { children &&
                  <SubMenu open={selectedMenu === menuIndex}>
                    <SubMenuContainer>
                      {
                        children.map(({ label, to }) => {
                          return <SubMenuLink href={to}>{label}</SubMenuLink>
                        })
                      }
                    </SubMenuContainer>
                  </SubMenu>
                }
              </MenuItem>
            )
          })
        }
      </MenuItems>
    </NavBar>
  )
}

export default Navigation;