import React from "react"
import { Link } from "gatsby"
import Logos from "../../images/logos/logos_footer_line.png"

const Footer = () => (
  <>
    <footer className="c-footer">
      <section className="wrapper">
        <section className="c-footer__container c-footer__submenu row">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <h5 className="c-footer__titles">Servicios</h5>
            <ul className="c-footer__list">
              <li><Link to="/dominios">Dominios</Link></li>
              <li>Hosting</li>
              <li>VPS</li>
              <li>Cloud</li>
              <li>Dedicados</li>
              <li>Housing</li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <h5 className="c-footer__titles">Empresa</h5>
            <ul className="c-footer__list">
              <li>La Empresa</li>
              <li>Programa de afiliados</li>
              <li>Aviso Legal</li>
              <li>Blog</li>
              <li>Contacto</li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <h5 className="c-footer__titles">Hosting CMS</h5>
            <ul className="c-footer__list">
              <li><Link to="/hosting/wordpress">Hosting Wordpres</Link></li>
              <li><Link to="/hosting/magento">Hosting Magento</Link></li>
              <li><Link to="/hosting/prestashop">Hosting Prestashop</Link></li>
              <li><Link to="/hosting/woocomerce">Hosting WooCommerce</Link></li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <h5 className="c-footer__titles">¿Por qué Rackmarkt?</h5>
            <ul className="c-footer__list">
              <li>Infraestructura</li>
              <li>Soporte Especializado</li>
              <li>Métodos de pago</li>
              <li>Servicios Administrados</li>
              <li>Servicios no Administrados</li>
            </ul>
          </div>
        </section>
        <section className="c-footer__logos-inline">
          <img src={Logos} alt="" />
        </section>
      </section>
    </footer>
    <section className="c-secondary-footer">
      <div className="wrapper">
        <div className="c-secondary-footer__container row">
          <div className="c-secondary-footer__copyright col-xs--12 col-sm-12 col-md-6">
            <img src="https://rackmarkt.pixerama.es/img/rackmarkt_mini.png" alt="Rackmarkt Logo" />
            <span>  · Copyright © 2020 Rackmarkt S.L. · Todos los derechos reservados · </span>
          </div>
          <div className="c-secondary-footer__texts col-xs--12 col-sm-12 col-md-6">
            <a href="/">Información Legal</a>
            <a href="/">Política de Privacidad</a>
            <a href="/">Cookies</a>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default Footer
