import { css } from 'styled-components';

const mediaQueries = ({ theme }) => {
  const getSizeFromBreakpoint = (breakpoint) => theme.screens[breakpoint];

  const lessThan = (breakpoint) => (styles) => {
    const content = Array.isArray(styles) ? styles : css`${styles}`;
    return css`
      @media(max-width: ${getSizeFromBreakpoint(breakpoint)}) {
        ${content}
      }
    `;
  }

  const greaterThan = (breakpoint) => (styles) => {
    const content = Array.isArray(styles) ? styles : css`${styles}`;
    return css`
      @media(min-width: ${getSizeFromBreakpoint(breakpoint)}) {
        ${content}
      }
    `;
  }
  const between = (minBreakpoint, maxBreakpoint) => (styles) => {
    const content = Array.isArray(styles) ? styles : css`${styles}`;
    return css`
      @media(min-width: ${getSizeFromBreakpoint(minBreakpoint)}) and
        (max-width: ${getSizeFromBreakpoint(maxBreakpoint)}) {
          ${content}
        }
    `;
  }

  return {
    lessThan,
    greaterThan,
    between,
  };
}

export default mediaQueries;