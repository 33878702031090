import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeContext } from '../../utils/ThemeContext'

import Header from "./header"
import Footer from "./footer";
import "../../scss/main.scss"
import { ThemeProvider } from 'styled-components';

import menuItems from '../../menu.json'

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme.currentTheme}>
      <Header siteTitle={data.site.siteMetadata.title} menuItems={menuItems} />
      <section style={{}}>
        <main>{children}</main>
      </section>
      <Footer />
    </ThemeProvider >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
